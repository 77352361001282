.loader {
    width: 50px;
    height: 50px;
    display: grid;
    animation: s4 8s infinite;
}
.loader::before,
.loader::after {
    content:"";
    grid-area: 1/1;
    border:8px solid;
    border-radius: 50%;
    border-color:#766DF4 #766DF4 #0000 #0000;
    mix-blend-mode: darken;
    animation: s4 2s infinite linear;
}
.loader::after {
    border-color:#0000 #0000 #E4E4ED #E4E4ED;
    animation-direction: reverse;
}

@keyframes s4{
    100%{transform: rotate(1turn)}
}