
.main{
    display: flex;
    flex-direction: column;
    max-width: 360px;
    align-items: center;
    text-align: center;
}
h2{
    font-size: 24px;
}
.text{
    margin-top: 36px;
}
.form{
    margin-top: 36px;
    height: 102px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.codeLink{
    margin-top: 22px;
    margin-bottom: 22px;
}
.error{
    margin-top: 22px;
    margin-bottom: 22px;
    color:red;
    cursor:pointer;
}