

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;

}

*:focus{
  text-decoration: none;
  outline:none
}

.App{
  min-height: 100vh;
  /* font-family: 'Roboto medium'; */
}




