.filepond--wrapper{
    padding-top: 30px;
    border:2px dashed #509ED6;
    border-radius: 4px;
    max-width: 311px;
    height: fit-content !important;
}


.filepond--panel-root {
    background-color: #D9D9D9 !important;
}
.file-uploader-text{
font-family: Roboto;
font-size: 16px;
font-weight: 500;
line-height: 21px;
letter-spacing: 0em;
text-align: center;
max-width: 220px;
}

.filepond--label-action{
    width: fit-content;
    margin-top: 5px !important;
    margin: auto;
padding: 0px 12px 0px 12px;
border-radius: 4px;
border: 3px solid #009FDA !important;
background-color:white;
font-family: Roboto;
font-size: 14px;
font-weight: 500;
letter-spacing: 0em;
text-align: center;
text-decoration: none !important;
color:#009FDA !important;
}
.filepond--label-action:hover{
    background-color: #F0F7FF;
}
.filepond--list-scroller{
    top:20px !important;
}