
.main{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 360px;

    text-align: center;
}
.text{
    margin-top: 30px;
margin-bottom: 36px;
}
.error{
    margin-top: 22px;
    cursor:pointer;
    color:red;
}
.linkCont{
    margin-top: 22px;
    margin-bottom: 22px;
}
.checkboxCont{
    margin-top: 62px;
    display: flex;
    align-items: baseline;
    width: 100%;
}
.codeLink{
    /*margin-top: 22px;*/
    /*margin-bottom: 22px;*/
}
.agreementCont{
    text-align: left;
    margin-left: 15px;
}
.agreementLinks{
    display: flex;
    flex-direction: column;
    margin-top: 28px;
}
.agreementLink{
    color: #757575;
    text-decoration: underline;
}